import { gql } from '@apollo/client';

export const GET_USABLE_PHONELINES = gql`
  query getUsablePhonelines($placeId: String) {
    phonelines(placeId: $placeId) {
      id
      category
      createdOn
      name
      mdn
      user {
        id
      }
      smsChannel {
        id
        isActive
        ... on TwilioChannelQL {
          mdn
          outboundSmsMdn
        }
        place {
          id
          createdOn
          name
        }
      }
    }
  }
`;
