import flatten from 'lodash/flatten';
import difference from 'lodash/difference';
import type {
  InitPubnubAction,
  StorePubNubClientAction,
} from '~/actions/pubnub';

export const PUBNUB_INITIAL_STATE = {
  pubnubClient: null,
  subscribedPlaces: [],
};

export type PubNubReducerState = {
  pubnubClient: PubNub | null | undefined;
  subscribedPlaces: Array<string>;
};

type Actions = InitPubnubAction | StorePubNubClientAction;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'placeIds' implicitly has an 'any' type.
const channelsForPlaces = placeIds =>
  flatten(
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'placeId' implicitly has an 'any' type.
    placeIds.map(placeId => [
      `${placeId}-pnpres`,
      `${placeId}-INTENT`,
      `${placeId}-MESSAGE`,
      `${placeId}-ORDER`,
      `${placeId}-SUGGESTED_REPLY`,
    ]),
  );

export default function PubNubReducer(
  state: PubNubReducerState = PUBNUB_INITIAL_STATE,
  action: Actions,
) {
  switch (action.type) {
    case 'INIT_PUBNUB': {
      const { pubnubClient, teams } = action.payload;
      const currIDs = channelsForPlaces(teams.map(x => x.id));
      const newIDs = difference(currIDs, state.subscribedPlaces);
      const missingIDs = difference(state.subscribedPlaces, currIDs);
      console.log('subscribing to ids', currIDs);

      pubnubClient.unsubscribe({
        channels: missingIDs,
      });

      pubnubClient.subscribe({
        channels: newIDs,
        withPresence: false,
      });

      return {
        ...state,
        subscribedPlaces: currIDs,
        pubnubClient,
      };
    }
    case 'STORE_PUBNUB_CLIENT': {
      return {
        ...state,
        pubnubClient: action.payload.pubnubClient,
      };
    }
    default:
      return state;
  }
}
