import { gql } from '@apollo/client';

export const OutboundCallCustomizationFragment = gql`
  fragment OutboundCallCustomizationFields on OutboundCallCustomizationQL {
    id
    voice
    leaveVoicemail
    disclosureSpeech
    voicemailSpeech
    hostNoAnswerSpeech
    renderedDisclosureSpeech
    renderedVoicemailSpeech
    renderedHostNoAnswerSpeech
    customAudioOverrides {
      disclosureSpeech
      voicemailSpeech
      hostNoAnswerSpeech
    }
  }
`;
