import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { lazyLoad } from '../util/lazyLoad';

import { DelayedLoadingIndicator } from './shared/DelayedLoadingIndicator';

const DemoAdminRoutes = lazyLoad(
  () => import('./public/demo/DemoAdminRoutes'),
  './public/demo/DemoAdminRoutes',
);

export const DemoAppRoutes = () => {
  return (
    <Suspense fallback={<DelayedLoadingIndicator />}>
      <Switch>
        <Route key="demo" path="/demo" component={DemoAdminRoutes} />
      </Switch>
    </Suspense>
  );
};
