import { gql } from '@apollo/client';

export const GET_FORTELLIS_SERVICE_ADVISORS = gql`
  query fortellisServiceAdvisors(
    $subscriptionId: String!
    $accountId: String!
    $numUsers: Int
    $userCursor: String
  ) {
    fortellisSubscription(
      subscriptionId: $subscriptionId
      accountId: $accountId
    ) {
      id
      serviceAdvisors {
        serviceAdvisorId
        name
      }
      repairOrderPlace {
        id
        placesUsers(first: $numUsers, after: $userCursor) {
          edges {
            node {
              id
              user {
                id
                displayName
              }
              registeredFortellisServiceAdvisor {
                id
                serviceAdvisorId
                name
                repairOrderSchedule
                nextFetchTime
              }
            }
          }
        }
      }
    }
  }
`;
