import { gql } from '@apollo/client';

import { OutboundCallCustomizationFragment } from '../../fragments/gql/outboundCallCustomization.gql';

export const UPLOAD_CUSTOM_OUTBOUND_CALL_AUDIO = gql`
  mutation UploadCustomOutboundCallAudio(
    $input: UploadCustomOutboundCallAudioInput!
  ) {
    uploadCustomOutboundCallAudio(input: $input) {
      outboundCallCustomization {
        ...OutboundCallCustomizationFields
      }
    }
  }
  ${OutboundCallCustomizationFragment}
`;
