import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import merge from 'lodash/merge';
import { ApolloClient, gql } from '@apollo/client';
import { safeRead } from './apollo';
import { GET_ACTIVE_INBOX } from '../queries/gql/getActiveInbox.gql';
import { GET_CURRENT_USER } from '../queries/gql/getCurrentUser.gql';
import { COLLECTION_NAMES, inboxIsCollection } from '../types';
import type { ActiveInboxOptions } from '../types';
import { removeEdgeFromConnection } from './relay';
import { ConversationItemStub } from '../fragments/gql/conversationItem.gql';
import { GET_MENTIONED_CONVERSATIONS } from '../queries/gql/getMentionedConversations.gql';

export const CONVERSATION_STUBS = gql`
  query conversations {
    conversations {
      edges {
        node {
          ...ConversationItemStub
        }
      }
    }
  }
  ${ConversationItemStub}
`;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'conversations' implicitly has an 'any' ... Remove this comment to see the full error message
const uniqueById = conversations => {
  const groupedBy: Record<string, Array<any>> = groupBy(
    conversations,
    'node.id',
  );

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'c' implicitly has an 'any' type.
  const mergedConversations = conversations.map(c =>
    merge({}, ...groupedBy[c.node.id].reverse()),
  );

  return uniqBy(mergedConversations, 'node.id');
};

export const inboxMatches = ({
  activeInboxId,
  conversationInboxId,
  conversationAssigneeId,
  currentUserId,
  amIMentioned,
  isMentionedConversation,
  activeInboxOptions,
}: {
  activeInboxId: string;
  conversationInboxId: string | null | undefined;
  conversationAssigneeId: string | null | undefined;
  currentUserId: string | null | undefined;
  amIMentioned: boolean;
  isMentionedConversation: boolean;
  activeInboxOptions: ActiveInboxOptions;
}): boolean => {
  if (
    !inboxIsCollection(activeInboxId) &&
    activeInboxId === conversationInboxId
  ) {
    // Not a collection and inbox matches
    return true;
  }
  if (activeInboxId === COLLECTION_NAMES.MENTIONS && isMentionedConversation) {
    if (activeInboxOptions.unreadOnly) {
      return amIMentioned;
    }
    return true;
  }

  if (
    activeInboxId &&
    [COLLECTION_NAMES.ASSIGNED, COLLECTION_NAMES.ADVISOR].includes(
      activeInboxId,
    ) &&
    conversationAssigneeId &&
    conversationAssigneeId === currentUserId
  ) {
    // Currently in assigned and conversationAssigneeId=currentUserId
    return true;
  }
  if (
    activeInboxId === COLLECTION_NAMES.UNASSIGNED &&
    !conversationAssigneeId
  ) {
    // Currently in assigned and conversationAssigneeId=currentUserId
    return true;
  }

  if (activeInboxId === COLLECTION_NAMES.ALL) {
    return true;
  }

  if (activeInboxId === COLLECTION_NAMES.STATUS) {
    return true;
  }

  return false;
};

export type UpdateActiveInboxConversations = Array<{
  id: string;
  inboxId?: string | null | undefined;
  assigneeId?: string | null | undefined;
  state?: ConversationState;
  lastUpdated?: string | null | undefined;
  waitingSince?: string | null | undefined;
  amIMentioned?: boolean;
  isMentionedConversation?: boolean;
}>;

export const updateActiveInbox = (
  client: ApolloClient<any>,
  apolloConversations: UpdateActiveInboxConversations,
) => {
  const currentUserQuery = safeRead(client, { query: GET_CURRENT_USER });
  const activeInboxQuery = safeRead(client, { query: GET_ACTIVE_INBOX });
  const currentConversationStubs = safeRead(client, {
    query: CONVERSATION_STUBS,
  });
  const currentMentionedConversationsStubs = safeRead(client, {
    query: GET_MENTIONED_CONVERSATIONS,
  });

  let currentStubs = currentConversationStubs;

  // HACK: mentions use a separate cache entry,
  // so use those as stubs if on mentions inbox
  // see end of this function for equivalent cache-write behaviour
  if (activeInboxQuery?.activeInbox?.inboxId === COLLECTION_NAMES.MENTIONS) {
    currentStubs = {
      conversations: currentMentionedConversationsStubs?.mentionedConversations,
    };
  }

  if (!activeInboxQuery || !currentStubs || !currentStubs.conversations) {
    const reportError = console.error ? console.error : console.log;
    reportError(
      'updateActiveInbox aborting, activeInbox=',
      activeInboxQuery,
      'currentStubs=',
      currentStubs,
    );

    return;
  }

  const { activeInbox } = activeInboxQuery;

  let newStubs = {
    ...currentStubs,
  };

  let action = 'ADD';
  for (const apolloConversation of apolloConversations) {
    if (
      !inboxMatches({
        activeInboxId:
          activeInbox.inboxId === 'status'
            ? COLLECTION_NAMES.ASSIGNED
            : activeInbox.inboxId,
        conversationInboxId: apolloConversation.inboxId,
        conversationAssigneeId: apolloConversation.assigneeId,
        currentUserId: get(currentUserQuery, 'currentUser.id'),
        amIMentioned: apolloConversation.amIMentioned ?? false,
        isMentionedConversation:
          apolloConversation.isMentionedConversation ?? false,
        activeInboxOptions: activeInbox.options || {},
      })
    ) {
      action = 'REMOVE';
    }
    if (
      activeInbox.state !== apolloConversation.state &&
      apolloConversation.inboxId &&
      apolloConversation.inboxId !== COLLECTION_NAMES.MENTIONS
    ) {
      action = 'REMOVE';
    }
    if (apolloConversation.state === 'DELETED') {
      action = 'REMOVE';
    }
    // add the conversation
    if (action === 'REMOVE') {
      newStubs = {
        ...newStubs,
        conversations: {
          ...newStubs.conversations,
          ...removeEdgeFromConnection(
            newStubs.conversations,
            apolloConversation.id,
          ),
        },
      };
    } else {
      newStubs = {
        ...newStubs,
        conversations: {
          ...newStubs.conversations,
          edges: uniqueById([
            {
              __typename: 'ConversationEdge',
              node: {
                __typename: 'ConversationQL',
                id: apolloConversation.id,
                lastUpdated: apolloConversation.lastUpdated,
                state: apolloConversation.state,
                inboxId: apolloConversation.inboxId,
                waitingSince: apolloConversation.waitingSince,
              },
            },

            ...newStubs.conversations.edges,
          ]),
        },
      };
    }
  }

  // HACK: conversations and mentioned conversations are stored in separate
  // cache entries, so we need to update them both.
  // we don't selectively write to one or another (like stubs)
  // to ease testing, and this doesn't matter anyways since we
  // refetch the collections on inbox change
  client.writeQuery({
    query: GET_MENTIONED_CONVERSATIONS,
    data: {
      mentionedConversations: newStubs.conversations,
    },
  });
  client.writeQuery({
    query: CONVERSATION_STUBS,
    data: newStubs,
  });
};
