import { all } from 'redux-saga/effects';

import {
  AccountModule,
  AdminModule,
  AuthModule,
  BadgeModule,
  BillingIdentityModule,
  BusinessSearchModule,
  HostedSmsModule,
  IntentsModule,
  PhoneLineModule,
  PhoneNumberModule,
  PlaceInfoModule,
  SignupModule,
  SubscriptionsModule,
  PlaceModule,
  UserModule,
  WidgetModule,
} from '@numbox/modules';

import { watchPubnubInitialization } from './subscribeToPubnub';
import watchActivelyTyping from './typing';
import { watchPageTitleAnimation } from './pageTitle';
import { watchIntercom } from '../modules/intercom';

// Modules
import { watchUserLogin, watchEventTracks } from '../modules/analytics';
import {
  watchAuthError,
  watchUserLogout,
  watchApolloTokenRefresh,
  watchAuthHeaderUpdate,
} from './auth';

import { watchDemoReset } from './demoReset';
import { watchSelectPlace } from '../modules/places';
import { watchGreetings } from '../modules/greetings';
import { watchSuggestedReply } from '../modules/suggestions';
import { watchTranslation } from '../modules/translation';
import { watchTextToSpeech } from '../modules/speech';

import * as CookiesModule from '../modules/cookie';

import { watchPhoneLineNavigation } from '../modules/phonelines';

export default function* rootSaga() {
  yield all([
    // modules
    watchAuthError(),
    watchEventTracks(),
    watchGreetings(),
    watchSuggestedReply(),
    watchApolloTokenRefresh(),
    watchAuthHeaderUpdate(),
    watchUserLogin(),
    watchUserLogout(),
    watchSelectPlace(),
    watchTranslation(),
    watchTextToSpeech(),
    watchIntercom(),
    watchPhoneLineNavigation(),
    // flows
    watchActivelyTyping(),
    watchPageTitleAnimation(),
    watchPubnubInitialization(),
    watchDemoReset(),
    AccountModule.AccountSagas(),
    AdminModule.AdminSagas(),
    AuthModule.AuthSagas(),
    BadgeModule.BadgeSagas(),
    BillingIdentityModule.BillingIdentitySagas(),
    BusinessSearchModule.BusinessSearchSagas(),
    CookiesModule.CookieSagas(),
    HostedSmsModule.HostedSmsSagas(),
    IntentsModule.IntentsSagas(),
    PhoneLineModule.PhoneLineSagas(),
    PhoneNumberModule.PhoneNumberSagas(),
    PlaceInfoModule.PlaceInfoSagas(),
    SignupModule.SignupSagas(),
    SubscriptionsModule.SubscriptionsSagas(),
    PlaceModule.PlaceSagas(),
    UserModule.UserSagas(),
    WidgetModule.WidgetSagas(),
  ]);
}
