import axios from 'axios';
import type { AxiosPromise } from 'axios';
import { Service } from '../Service';
import type {
  ApiAdminCreateDemoUserRequest,
  ApiAdminCreateDemoUserResponse,
  ApiAdminDeleteUserRequest,
  ApiAdminDeleteUserResponse,
  ApiAdminFetchDemoUsersRequest,
  ApiAdminFetchDemoUsersResponse,
  ApiDemoResetRequest,
  ApiDemoResetResponse,
  ApiDemoStatusResponse,
} from './types';

class AdminService implements Service {
  static demoReset(
    params: ApiDemoResetRequest,
  ): AxiosPromise<ApiDemoResetResponse> {
    const {
      resetMode,
      name,
      address,
      email,
      profileType,
      businessHours,
      locationLink,
      appointmentLink,
      reviewLink,
      brandingType,
      inbox1,
      inbox2,
      inbox3,
    } = params;
    let path = `/resetDemo?reset_mode=${encodeURIComponent(
      resetMode,
    )}&businessName=${encodeURIComponent(name)}&email=${encodeURIComponent(
      email,
    )}`;

    if (resetMode === 'full') {
      path = `${path}&synchronous=0&verified=1&brand=''&profileType=${encodeURIComponent(
        profileType,
      )}&address=${encodeURIComponent(
        address,
      )}&businessHours=${encodeURIComponent(
        businessHours,
      )}&locationLink=${encodeURIComponent(
        locationLink,
      )}&appointmentLink=${encodeURIComponent(
        appointmentLink,
      )}&reviewLink=${encodeURIComponent(
        reviewLink,
      )}&brandingType=${encodeURIComponent(
        JSON.stringify(brandingType),
      )}&inbox1=${encodeURIComponent(inbox1)}&inbox2=${encodeURIComponent(
        inbox2,
      )}&inbox3=${encodeURIComponent(inbox3)}`;
    }
    return axios.get(path);
  }

  static demoStatus(): AxiosPromise<ApiDemoStatusResponse> {
    return axios.get('/demoStatus');
  }

  static deleteUser(
    params: ApiAdminDeleteUserRequest,
  ): AxiosPromise<ApiAdminDeleteUserResponse> {
    const { account_id: accountId, ...rest } = params;
    const path = `/api/admin/${accountId}/users`;
    return axios.delete(path, { data: rest });
  }

  static fetchDemoUsers(
    params: ApiAdminFetchDemoUsersRequest,
  ): AxiosPromise<ApiAdminFetchDemoUsersResponse> {
    const { account_id: accountId } = params;
    const path = `/api/admin/${accountId}/users`;
    return axios.get(path);
  }

  static createDemoUser(
    params: ApiAdminCreateDemoUserRequest,
  ): AxiosPromise<ApiAdminCreateDemoUserResponse> {
    const { account_id: accountId, ...rest } = params;
    const path = `/api/admin/${accountId}/users`;
    return axios.post(path, rest);
  }
}

export default AdminService;
