import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { lazyLoad } from '../util/lazyLoad';

import { DelayedLoadingIndicator } from './shared/DelayedLoadingIndicator';

const AdminPortal = lazyLoad(
  () => import('./admin/AdminPortal/AdminPortal'),
  './admin/AdminPortal/AdminPortal',
);

const AdminAuth0Redirect = lazyLoad(
  () => import('./admin/AdminPortal/AdminAuth0Redirect'),
  './admin/AdminPortal/AdminAuth0Redirect',
);

const AdminDashboard = lazyLoad(
  () => import('./admin/AdminDashboard'),
  './admin/AdminDashboard',
);

export const AdminAppRoutes = () => {
  return (
    <Suspense fallback={<DelayedLoadingIndicator />}>
      <Switch>
        <Route key="admin" exact path="/admin" component={AdminPortal} />
        <Route
          key="admin-auth0-redirect"
          exact
          path="/admin/auth0redirect"
          component={AdminAuth0Redirect}
        />
        <Route
          key="admin-dashboard"
          path="/admin/dashboard"
          component={AdminDashboard}
        />
      </Switch>
    </Suspense>
  );
};
