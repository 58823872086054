import { gql } from '@apollo/client';

export const AUTO_COMPLETE_CONVERSATIONS = gql`
  query searchConversations(
    $search: String!
    $first: Int!
    $after: String
    $sort: [ConversationModelSortEnum]
  ) {
    autocompleteConversations(
      search: $search
      first: $first
      after: $after
      sort: $sort
    ) @connection(key: "autocompleteConversations", filter: []) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          lastUpdated
          customer {
            id
            address
            displayName
            displayNameConfidence
            source
            cnamContact {
              id
              firstName
              lastName
            }
            fortellisContact {
              id
              firstName
              lastName
              companyName
              email
              phoneNumber
            }
          }
          place {
            id
            name
          }
        }
      }
    }
  }
`;
