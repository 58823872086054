import { gql } from '@apollo/client';
import { TeamFragment } from './TeamFragment.gql';
import { UserItemFragment } from './userItem.gql';

export const VoiceStepFieldsFragment = gql`
  fragment VoiceStepFields on VoiceScriptStep {
    __typename
    id
    canToggle
    enabledAfterBusinessHours
    enabledDuringBusinessHours
    executionOrder
    label
    speech
    renderedSpeech
    stepType
    uiHints {
      x
      y
    }
    customAudioOverrides {
      speech
      afterHoursSpeech
      canWeTextSpeech
      onYesCanWeTextSpeech
      onNoCanWeTextSpeech
      onFailCanWeTextSpeech
      clarifyCanWeTextSpeech
      clarifySpeech
      onYesSpeech
      onNoSpeech
      onFailureSpeech
      noAnswerSpeech
      disclosureContent
    }
    ... on VoiceScriptStepQL {
      id
    }
    ... on TextOptinStepQL {
      renderedOptinTextMessage
      renderedAfterHoursTextMessage
      optinTextMessage
      useAfterHoursTextMessage
      afterHoursTextMessage
      clarifySpeech
      onYesSpeech
      onNoSpeech
      onFailureSpeech
      onYesStepId
      onNoStepId
    }
    ... on AskYesNoQuestionStepQL {
      clarifySpeech
      onYesSpeech
      onNoSpeech
      onFailureSpeech
      onYesStepId
      onNoStepId
    }
    ... on IntentQuestionQL {
      messageToSend
      renderedMessageToSend
      purpose

      canWeTextSpeech

      onYesCanWeTextSpeech
      onNoCanWeTextSpeech
      onFailCanWeTextSpeech
      clarifyCanWeTextSpeech

      clarifySpeech
      onYesSpeech
      onNoSpeech
      onFailureSpeech
      onYesStepId
      onNoStepId
      onYesButNoTextStepId
    }
    ... on SpecialQuestionQL {
      renderedMessageToSend
      messageToSend
      canWeTextSpeech

      onYesCanWeTextSpeech
      onNoCanWeTextSpeech
      onFailCanWeTextSpeech
      clarifyCanWeTextSpeech

      clarifySpeech
      onYesSpeech
      onNoSpeech
      onFailureSpeech
      onYesStepId
      onNoStepId
      onYesButNoTextStepId
    }
    ... on QualifyStepQL {
      onHasResponseId
      onNoResponseId
      shorthandPrompt
      acknowledgementSpeech
    }
    ... on PressAnyKeyStepQL {
      afterHoursSpeech
      renderedAfterHoursSpeech
      renderedOptinTextMessage
      renderedAfterHoursTextMessage
      optinTextMessage
      useAfterHoursTextMessage
      afterHoursTextMessage
      useAfterHoursTextMessage
      useCustomAudio
      useCustomAudioForAfterHours
    }
    ... on GreetingStepQL {
      afterHoursSpeech
      renderedAfterHoursSpeech
      playDisclosureRecording
      disclosureContent
      softOpenSpeech
      confirmPhonenumberSpeech
      collectNewPhonenumberSpeech
      collectPhonenumberFallbackSpeech
      clarificationSpeech
      isCorrectQuestionSpeech
      yesSpeech
      noSpeech
      retrySpeech
      affirmationSpeech
      nextStepId
    }
    ... on AutoAssignStepQL {
      respectExistingAssignment
      assignee {
        ...UserItemFields
      }
      team {
        ...TeamFields
      }
      autoAssignNextStepId
    }
    ... on CallForwardingStepQL {
      callTimeout
      forwardingMdns {
        forwardingMdn
        forwardingMode
      }
      useNumaCallerId
      noAnswerSpeech
      onConnectedStepId
      onNoAnsweringStepId
    }
    ... on CallRecordingStepQL {
      startRecording
      playDisclosureRecording
      disclosureContent
      nextStepId
    }
    ... on PickupStepQL {
      id
      callerRecognizedStepId
    }
    ... on StatusUpdaterStepQL {
      id
      statusGivenStepId
      noStatusGivenStepId
    }
    ... on LabelConversationStepQL {
      id
      nextStepId
      labelName
      expirationHours
    }
    ... on CheckForLabelStepQL {
      id
      onHasLabelId
      onMissingLabelId
      labelName
    }
    ... on SpeakStepQL {
      id
      afterHoursSpeech
      renderedAfterHoursSpeech
      nextStepId
    }
    ... on QuestionBotStepQL {
      id
      nextStepId
      finishedSpeech
      questionAgentSettingsId
    }
    ... on NoteTakerStepQL {
      id
      nextStepId
      finishedSpeech
      noteTakerId
    }
    ... on AppointmentAgentStepQL {
      id
      nextStepId
      failureStepId
      notBookedRecallStepId
      notBookedOtherStepId
      finishedSpeech
      outcomeNotBookedRecallSpeech
      outcomeNotBookedOtherSpeech
      outcomeNotBookedWantsHumanSpeech
      appointmentAgentSettingsId
      appointmentLeadStepId
      notAnAppointmentLeadStepId
      clarifyIntentFirstEnabled
      clarifySpeech
      yesSpeech
      noSpeech
      failureSpeech
    }
    ... on ConversationStateStepQL {
      id
      desiredConversationState
      nextStepId
    }
    ... on CheckBusinessHoursStepQL {
      id
      onOpenStepId
      onClosedStepId
    }
    ... on SmartTopicStepQL {
      id
      llmPromptOverride
      llmModelOverride
      topics {
        id
        topic
        nextStepId
        order
      }
      purpose
      additionalContext
      acknowledgementSpeech
      noMatchStepId
    }
  }
  ${UserItemFragment}
  ${TeamFragment}
`;
