import { gql } from '@apollo/client';

export const AutomationTriggerFragment = gql`
  fragment AutomationTriggerFields on AutomationTrigger {
    __typename
    id
    triggerType
    uiHints {
      x
      y
    }
    ... on LabelAddedTriggerQL {
      labelsToWatch
    }
    ... on LabelRemovedTriggerQL {
      labelsToWatch
    }
    ... on MessageReceivedTriggerQL {
      id
    }
    ... on RepairOrderOpenedTriggerQL {
      opcodesFilter {
        category
        code
      }
      includeWhenMatched
    }
    ... on RepairOrderClosedTriggerQL {
      opcodesFilter {
        category
        code
      }
      includeWhenMatched
    }
    ... on RepairOrderUpdatedTriggerQL {
      opcodesFilter {
        category
        code
      }
      updateFilter {
        conditions {
          function
          keyName
          newValue {
            value
            type
          }
          oldValue {
            value
            type
          }
        }
        operator
      }
      includeWhenMatched
    }
    ... on ConversationArchivedTriggerQL {
      includeClosedByNuma
      includeActiveConversations
      daysSinceOutbound
      daysSinceInbound
    }
    ... on AppointmentLeadCreatedTriggerQL {
      rulesQuery
    }
    ... on AppointmentLeadUpdatedTriggerQL {
      rulesQuery
    }
  }
`;

export const AutomationFragment = gql`
  fragment AutomationFields on AutomationQL {
    __typename
    id
    state
    name
    lastUpdated
    firstActionId
    dateLastPublished
    placeId
    publishedVersion
    latestVersion
    enrollmentCriteria {
      id
      preventConcurrentEnrollment
      executionLimit
      duration
    }
    exitTriggers {
      ...AutomationTriggerFields
    }
    startTrigger {
      ...AutomationTriggerFields
    }
    actions {
      id
      actionType
      customersWaiting
      nextActionId
      uiHints {
        x
        y
      }
      ... on SendMessageActionQL {
        messageToSend
        friendlyTime
        automationChannels {
          id
        }
        desiredConversationState
        textWithCare
        selectedChannelId
      }
      ... on ManageLabelActionQL {
        labels {
          operation
          label
        }
      }
      ... on HasLabelActionQL {
        onYesActionId
        onNoActionId
        labelsToCheck
        labelCheckOperation
      }
      ... on IsBusinessHoursActionQL {
        onYesActionId
        onNoActionId
      }
      ... on ScheduleMessageActionQL {
        messageToSend
        friendlyTime
        minutesUntilSent
        automationChannels {
          id
        }
        desiredConversationState
        textWithCare
        selectedChannelId
      }
      ... on LeaveNoteActionQL {
        messageToSend
        desiredConversationState
      }
      ... on SummarizeConversationActionQL {
        desiredConversationState
      }
      ... on NotifyTeamMemberActionQL {
        messageToSend
        desiredConversationState
        notifyOwner
        userIdsMentioned
        teamIdsMentioned
      }
      ... on AssignConversationActionQL {
        assignee {
          id
          email
          fullName
          firstName
          lastName
        }
        team {
          id
          name
          avatar
        }
        assigneeId
        teamId
        respectExistingAssignments
      }
      ... on SendFeedbackActionQL {
        friendlyTime
        automationChannels {
          id
        }
        desiredConversationState
        textWithCare
        selectedChannelId
      }
      ... on SendReviewActionQL {
        messageToSend
        friendlyTime
        automationChannels {
          id
        }
        desiredConversationState
        textWithCare
        selectedChannelId
      }
      ... on WaitActionQL {
        minutesToWait
      }
      ... on ChangeConversationStateActionQL {
        desiredConversationState
      }
    }
  }
  ${AutomationTriggerFragment}
`;
