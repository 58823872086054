import { gql } from '@apollo/client';

export const CHANGE_REPAIR_ORDER_SCHEDULE = gql`
  mutation ChangeRepairOrderSchedule($input: ChangeRepairOrderScheduleInput!) {
    changeRepairOrderSchedule(input: $input) {
      registeredFortellisServiceAdvisor {
        id
        serviceAdvisorId
        name
        repairOrderSchedule
        nextFetchTime
      }
    }
  }
`;
