import get from 'lodash/get';

import { ApiConversation, ApiMessage } from '@numbox/services';

import type { WebReducerState } from '../reducers/types';
import type {
  ReceivePushNotificationAction,
  ReceiveNotificationToBroadcastAction,
} from './messages';

export const getMessageToNotify = (state: WebReducerState) =>
  state.notifications.lastMessage;

export const NOTIFICATIONS_INITIAL_STATE = {
  lastMessage: null,
};

export const DEFAULT_AVATAR =
  'https://storage.googleapis.com/media.beta.numberai.com/browserPushNuma.png';

const notificationBodyFromMessage = (
  pubNubMessage: PubNubMessage<{
    payload: {
      conversation: ApiConversation;
      message?: ApiMessage;
      notification?: {
        title: string;
        body: string;
        iconUrl: string;
      };
    };
  }>,
) => {
  const { conversation, message } = pubNubMessage.message.payload;

  const title = get(pubNubMessage, 'message.pn_gcm.data.title');
  const body = get(pubNubMessage, 'message.pn_gcm.data.message');
  const iconUrl =
    get(message, 'sender.avatar_url') ||
    get(conversation, 'customer.avatar_url') ||
    DEFAULT_AVATAR;

  return {
    title,
    body,
    iconUrl,
    conversationId: conversation.id,
  };
};

export type NotificationReducerState = {
  lastMessage:
    | {
        title: string;
        iconUrl: string;
        body: string;
        conversationId: string;
      }
    | null
    | undefined;
};

const NotificationReducer = (
  state: NotificationReducerState = NOTIFICATIONS_INITIAL_STATE,
  action: ReceivePushNotificationAction | ReceiveNotificationToBroadcastAction,
) => {
  switch (action.type) {
    case 'RECEIVE_PUSH_NOTIFICATION':
      return {
        ...state,
        lastMessage: notificationBodyFromMessage(action.payload.message),
      };

    case 'RECEIVE_NOTIFICATION_TO_BROADCAST':
      return {
        ...state,
        lastMessage: action.payload.notification,
      };

    default:
      return state;
  }
};

export default NotificationReducer;
