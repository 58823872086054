import { gql } from '@apollo/client';

import { OutboundCallCustomizationFragment } from '../../fragments/gql/outboundCallCustomization.gql';

export const UPDATE_OUTBOUND_CALL_CUSTOMIZATION = gql`
  mutation UpdateOutboundCallCustomization(
    $input: UpdateOutboundCallCustomizationInput!
  ) {
    updateOutboundCallCustomization(input: $input) {
      outboundCallCustomization {
        ...OutboundCallCustomizationFields
      }
    }
  }
  ${OutboundCallCustomizationFragment}
`;
