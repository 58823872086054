import { gql } from '@apollo/client';

export const GET_DMS_OBJECT_HISTORY = gql`
  query getDMSObjectHistory($numaObjectId: String!, $dmsType: DMSHistoryType!) {
    dmsObjectHistory(numaObjectId: $numaObjectId, dmsType: $dmsType) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          diff
          createdOn
        }
      }
      lastData(numaObjectId: $numaObjectId, dmsType: $dmsType)
    }
  }
`;
