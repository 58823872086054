import { gql } from '@apollo/client';

export const REGISTER_FORTELLIS_SERVICE_ADVISOR = gql`
  mutation RegisterFortellisServiceAdvisor(
    $input: RegisterFortellisServiceAdvisorInput!
  ) {
    registerFortellisServiceAdvisor(input: $input) {
      serviceAdvisor {
        id
        serviceAdvisorId
        name
      }
      placesUser {
        id
        user {
          id
          displayName
        }
        registeredFortellisServiceAdvisor {
          id
          serviceAdvisorId
          name
          repairOrderSchedule
          nextFetchTime
        }
      }
    }
  }
`;
