import { gql } from '@apollo/client';
import { AttachmentItemFragment } from './attachment.gql';
import { DialogFlowBotDataFragment } from './dialogFlowBotDataFragment.gql';
import { ReasonSentFragment } from './reasonSent.gql';
import { RepairOrderFragment } from './repairOrderFragment.gql';
import { OutboundConferenceCallFragment } from './outboundConferenceCall.gql';
import { PaymentRequestFragment } from './paymentRequestFragment.gql';
import { PhoneCallFragment } from './phoneCall.gql';

export const MessageItemFragment = gql`
  fragment MessageItemFields on MessageQL {
    id
    canTeachNuma
    intentDisplayName
    intentId
    channelId
    title
    deliveryStatus
    deliveryError
    isInbound
    isSuggestion
    messageFormat
    content
    timestamp
    capsLockFixed
    mentions {
      userId
      tag
    }
    attachments {
      ...AttachmentItemFields
    }
    sender {
      id
      address
      firstName
      lastName
      source
      displayName
      displayNameConfidence
    }
    conversation {
      id
    }
    reasonSent {
      ...ReasonSentFields
    }
    call {
      ...PhoneCallFields
    }
    outboundProxyCall {
      id
      endtime
      startTime
      callStatus
      callDuration
      transcriptId
      callRecording {
        id
        url
      }
    }
    outboundConferenceCall {
      ...OutboundConferenceCallFields
    }
    loggedCallInteraction
    repairOrder {
      ...RepairOrderFields
    }
    paymentRequest {
      ...PaymentRequestFields
    }
    dialogFlowBotData {
      ...DialogFlowBotDataFields
    }
  }
  ${AttachmentItemFragment}
  ${DialogFlowBotDataFragment}
  ${ReasonSentFragment}
  ${RepairOrderFragment}
  ${OutboundConferenceCallFragment}
  ${PaymentRequestFragment}
  ${PhoneCallFragment}
`;
