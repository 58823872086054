import React from 'react';

const AUTH0_CLIENT_ID_WEB = import.meta.env.VITE_AUTH0_CLIENT_ID;
const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;

export const Auth0TenantContext = React.createContext<{
  auth0ClientId: string;
  auth0Domain: string;
}>({
  auth0ClientId: AUTH0_CLIENT_ID_WEB,
  auth0Domain: AUTH0_DOMAIN,
});
