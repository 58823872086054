import { gql } from '@apollo/client';

export const PhoneCallFragment = gql`
  fragment PhoneCallFields on PhoneCallQL {
    id
    callDuration
    isActive
    endtime
    startTime
    conversationId
    transcriptId
    hasReturnCallsRouting
    returnCallsRoutingOutcome
    returnCallsRoutedTo
    participant {
      id
      address
      displayName
    }
    redirectedFrom {
      id
      address
      displayName
    }
    callRecording {
      id
      url
    }
  }
`;
