import {
  type ButtonOwnProps,
  type IconButtonOwnProps,
  type Theme,
} from '@mui/material';

type Color = ButtonOwnProps['color'] | IconButtonOwnProps['color'];

export const getColorTheme = (theme: Theme, color: Color) =>
  theme.palette[
    color && color !== 'inherit' && color !== 'default' ? color : 'primary'
  ];

export const getMainColor = (theme: Theme, color: Color) =>
  getColorTheme(theme, color).main;

export const getContrastColor = (theme: Theme, color: Color) =>
  getColorTheme(theme, color).contrastText;

export const getBackgroundColor = (theme: Theme, color: Color) =>
  getColorTheme(theme, color).background;
