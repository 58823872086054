import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { lazyLoad } from '../util/lazyLoad';

import RequireAuth from './RequireAuth';

import { DelayedLoadingIndicator } from './shared/DelayedLoadingIndicator';

const LoginWithToken = lazyLoad(
  () => import('./public/LoginWithToken'),
  './public/LoginWithToken',
);

const ForgotPassword = lazyLoad(
  () => import('./public/forms/ForgotPassword/ForgotPasswordPage'),
  './public/forms/ForgotPassword/ForgotPasswordPage',
);

const AcceptInvite = lazyLoad(
  () => import('./public/forms/AcceptInvite/AcceptInvitePage'),
  './public/forms/AcceptInvite/AcceptInvitePage',
);

const ResetPassword = lazyLoad(
  () => import('./public/forms/ResetPassword/ResetPasswordPage'),
  './public/forms/ResetPassword/ResetPasswordPage',
);

const NewLogin = lazyLoad(
  () => import('./public/forms/NewLogin/NewLogin'),
  './public/forms/NewLogin/NewLogin',
);

const Main = lazyLoad(() => import('./main'), './main');

const WithAuth = RequireAuth(Main);

export const MainAppRoutes = () => {
  return (
    <Suspense fallback={<DelayedLoadingIndicator />}>
      <Switch>
        <Route key="login" path="/login" component={NewLogin} />
        <Route key="newLogin" path="/new_login" component={NewLogin} />

        <Route
          key="pass"
          exact
          path="/forgotPassword"
          component={ForgotPassword}
        />

        <Route key="invite" exact path="/invite" component={AcceptInvite} />
        <Route key="reset" exact path="/reset" component={ResetPassword} />
        <Route
          key="token"
          exact
          path="/verify_account"
          component={LoginWithToken}
        />

        <Route component={WithAuth} />
      </Switch>
    </Suspense>
  );
};
