import { gql } from '@apollo/client';

export const REFRESH_FORTELLIS_SERVICE_ADVISORS = gql`
  mutation RefreshFortellisServiceAdvisors(
    $input: RefreshFortellisServiceAdvisorsInput!
  ) {
    refreshFortellisServiceAdvisors(input: $input) {
      fortellisSubscription {
        id
        serviceAdvisors {
          id
          serviceAdvisorId
          name
        }
      }
    }
  }
`;
