import type { ApolloContext } from '../../types';
import { safeRead } from '../../util';
import { LOAD_CHAT_HISTORY } from '../../queries/gql/loadChatHistory.gql';
import {
  getChatHistoryQueryVariables,
  LOAD_CHAT_HISTORY_NUMBER_OF_MESSAGES,
} from '../../queries/LoadChatHistory';
import { PhoneCallFragment } from '../../fragments/gql/phoneCall.gql';
import { GET_CURRENT_USER } from '../../queries/gql/getCurrentUser.gql';

export const convertPhonecall = (
  call: PubNubInboundPhoneCallModel,
): PhoneCallFields => {
  return {
    __typename: 'PhoneCallQL',
    id: call.id,
    callDuration: call.call_duration || null,
    isActive: call.is_active,
    startTime: call.start_time,
    endtime: call.endtime,
    conversationId: call.conversation_id,
    transcriptId: call.transcript_id || null,
    hasReturnCallsRouting: call.has_return_calls_routing || false,
    returnCallsRoutingOutcome: call.return_calls_routing_outcome || null,
    returnCallsRoutedTo: call.return_calls_routed_to || null,
    participant: {
      __typename: 'ParticipantQL',
      id: call.participant.id,
      address: call.participant.address,
      displayName: call.participant.display_name,
    },
    redirectedFrom: call.redirected_from
      ? {
          __typename: 'ParticipantQL',
          id: call.redirected_from.id,
          address: call.redirected_from.address,
          displayName: call.participant.display_name,
        }
      : null,
    callRecording: call.call_recording
      ? {
          __typename: 'CallRecordingQL',
          id: call.call_recording.id,
          url: call.call_recording.url ?? null,
        }
      : null,
  };
};
/* eslint-disable react/destructuring-assignment */
export const PubNubInboundCallStatusUpdated = (
  obj: any,
  args: { call: PubNubInboundPhoneCallModel; conversation_id: string },
  { client }: ApolloContext,
) => {
  const conversationId = args.conversation_id;

  const currentUser = safeRead<getCurrentUser, getCurrentUserVariables>(
    client,
    { query: GET_CURRENT_USER },
  );

  const chatHistoryQueryVariables = getChatHistoryQueryVariables({
    conversationId,
    withScheduledMessages: true,
    numMessagesToLoad: LOAD_CHAT_HISTORY_NUMBER_OF_MESSAGES,
    includeSystemMessages:
      currentUser?.currentUser?.viewPreferences.systemMessageSettings
        ?.showSystemMessages,
  });

  const data = safeRead<loadChatHistory, loadChatHistoryVariables>(client, {
    query: LOAD_CHAT_HISTORY,
    variables: chatHistoryQueryVariables,
  });

  if (!data) {
    return null;
  }

  const phonecall = convertPhonecall(args.call);

  const activePhoneCallsWithoutMessages = [
    ...data?.activePhoneCallsWithoutMessages.filter(
      (call: loadChatHistory$activePhoneCallsWithoutMessages) =>
        call.id !== phonecall.id,
    ),
    phonecall,
  ];

  client.writeQuery<loadChatHistory, loadChatHistoryVariables>({
    query: LOAD_CHAT_HISTORY,
    variables: chatHistoryQueryVariables,
    data: {
      ...data,
      activePhoneCallsWithoutMessages,
    },
  });

  client.writeFragment({
    id: `PhoneCallQL:${phonecall.id}`,
    fragment: PhoneCallFragment,
    fragmentName: 'PhoneCallFields',
    data: phonecall,
  });

  return null;
};

/* eslint-enable */
