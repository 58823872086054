import type { ApiConversation, ApiMessage } from '@numbox/services';

export const NEW_CONVERSATION_ID = 'NEW_CONVERSATION';
export const NEW_PARTICIPANT = 'NEW_CUSTOMER';

export const PUBNUB_ACTION_TYPES = {
  STATE_CHANGE: 'state-change',
};

export type PubNubStateChangeEvent<T> = {
  channel: string;
  subscription: any;
  actualChannel: any;
  subscribedChannel: string;
  action: 'state-change';
  state: T;
  timetoken: string;
  occupancy: number;
  uuid: string;
  timestamp: Date;
};

export type PubNubConversationDeleted = {
  type: 'CONVERSATION_DELETED';
  payload: {
    conversation: ApiConversation;
  };
};

export type PubnubConversationRecovered = {
  type: 'CONVERSATION_RECOVERED';
  payload: {
    conversation: ApiConversation;
  };
};

export type PubnubConversationAssigned = {
  type: 'CONVERSATION_ASSIGNED';
  payload: { conversation: ApiConversation };
};

export type PubNubConversationTimestamp = {
  id: string;
  timestamp: string;
};

export type PubnubConversationArchived = {
  type: 'CONVERSATION_ARCHIVED';
  payload: {
    conversation: ApiConversation;
  };
};

export type PubnubConversationsArchived = {
  type: 'CONVERSATIONS_ARCHIVED';
  payload: {
    inbox_id: string;
    conversations: Array<PubNubConversationTimestamp>;
  };
};

export type PubnubConversationRestored = {
  type: 'CONVERSATION_RESTORED';
  payload: {
    conversation: ApiConversation;
  };
};

export type PubnubConversationsRestored = {
  type: 'CONVERSATIONS_RESTORED';
  payload: {
    inbox_id: string;
    conversations: Array<PubNubConversationTimestamp>;
  };
};

export type PubNubMessageDeliveryUpdated = {
  type: 'MESSAGE_DELIVERY_UPDATED';
  payload: { message: ApiMessage };
};

export type PubNubMessageReceived = {
  type: 'MESSAGE_RECEIVED';
  payload: {
    conversation: ApiConversation;
    message: ApiMessage;
  };
};

export type PubNubSuggestedReply = {
  type: 'SUGGESTED_REPLY';
  payload: {
    conversation: ApiConversation;
    message: ApiMessage;
  };
};

export type PubNubMessageSent = {
  type: 'MESSAGE_SENT';
  payload: {
    conversation: ApiConversation;
    message: ApiMessage;
  };
};

export type PubNubMessageUpdated = {
  type: 'MESSAGE_UPDATED';
  payload: {
    conversation: ApiConversation;
    message: ApiMessage;
  };
};

export type PubNubMessageTypes =
  | PubnubConversationArchived
  | PubnubConversationsArchived
  | PubnubConversationAssigned
  | PubnubConversationRestored
  | PubnubConversationsRestored
  | PubNubMessageDeliveryUpdated
  | PubNubMessageReceived
  | PubNubSuggestedReply
  | PubNubMessageSent
  | PubNubConversationDeleted;
