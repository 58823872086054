import React from 'react';
import { useAppSelector } from '~/effects/redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import type { Store } from 'redux';
import { ApolloClient } from '@apollo/client';
import jwt_decode from 'jwt-decode';
import type { JwtPayload } from 'jwt-decode';

import { ApolloLinksWrapper } from './ApolloLinksWrapper';
import { Auth0TenantContext } from './Auth0TenantContext';

import usePrevious from '../util/usePrevious';
import { userLoggedIn } from '../util/auth';
import { getAccessToken } from '../modules/auth';

type Props = {
  client: ApolloClient<any>;
  store: Store<any, any>;
  children: React.ReactNode;
};

const AUTH0_CLIENT_ID_WEB = import.meta.env.VITE_AUTH0_CLIENT_ID;
const AUTH0_CLIENT_ID_DEMO = import.meta.env.VITE_AUTH0_CLIENT_ID_DEMO;
const AUTH0_CLIENT_ID_ADMIN = import.meta.env.VITE_AUTH0_CLIENT_ID_ADMIN;
const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;
const AUTH0_API_AUDIENCE = import.meta.env.VITE_AUTH0_API_AUDIENCE;

type Auth0Connection = 'web' | 'demo' | 'admin';

const getAuth0ConnectionFromPath = (pathname: string) => {
  let auth0Connection: Auth0Connection;
  if (pathname.startsWith('/demo')) {
    auth0Connection = 'demo';
  } else if (pathname.startsWith('/admin')) {
    auth0Connection = 'admin';
  } else {
    auth0Connection = 'web';
  }

  let auth0ClientId;
  if (auth0Connection === 'web') {
    auth0ClientId = AUTH0_CLIENT_ID_WEB;
  } else if (auth0Connection === 'demo') {
    auth0ClientId = AUTH0_CLIENT_ID_DEMO;
  } else if (auth0Connection === 'admin') {
    auth0ClientId = AUTH0_CLIENT_ID_ADMIN;
  }

  return { auth0Connection, auth0ClientId };
};

const getAuth0ConnectionFromToken = (
  token: string | null | undefined,
): { auth0Connection: Auth0Connection; auth0ClientId: string } | null => {
  if (!token) {
    return null;
  }

  try {
    const decodedToken = jwt_decode<JwtPayload & { azp?: string }>(token);
    if (decodedToken?.iss === `https://${AUTH0_DOMAIN}/` && decodedToken?.azp) {
      if (decodedToken.azp === AUTH0_CLIENT_ID_WEB) {
        return { auth0Connection: 'web', auth0ClientId: AUTH0_CLIENT_ID_WEB };
      }
      if (decodedToken.azp === AUTH0_CLIENT_ID_DEMO) {
        return { auth0Connection: 'demo', auth0ClientId: AUTH0_CLIENT_ID_DEMO };
      }
      if (decodedToken.azp === AUTH0_CLIENT_ID_ADMIN) {
        return {
          auth0Connection: 'admin',
          auth0ClientId: AUTH0_CLIENT_ID_ADMIN,
        };
      }
    }

    return null;
  } catch (e) {
    return null;
  }
};

const getInitialAuth0Details = (
  pathname: string,
): { auth0Connection: Auth0Connection; auth0ClientId: string } => {
  const pathDetails = getAuth0ConnectionFromPath(pathname);
  const refreshToken = userLoggedIn();
  if (refreshToken) {
    const tokenDetails = getAuth0ConnectionFromToken(refreshToken);
    if (tokenDetails) {
      // If we already have a web auth, but we are on the demo or admin app
      // allow the user to do another login to get the correct token
      if (
        tokenDetails.auth0Connection === 'web' &&
        pathDetails.auth0Connection !== 'web'
      ) {
        return pathDetails;
      }

      return tokenDetails;
    }
  }

  return pathDetails;
};

const getRedirectUrl = (connection: Auth0Connection) => {
  if (connection === 'demo') {
    return '/demo/auth0redirect';
  }
  if (connection === 'admin') {
    return '/admin/auth0redirect';
  }
  return '/login/auth0redirect';
};

export const Auth0Wrapper = ({ client, store, children }: Props) => {
  const history = useHistory();
  const location = useLocation();

  const accessToken = useAppSelector(getAccessToken);
  const previousAccessToken = usePrevious(accessToken);

  const [{ auth0Connection, auth0ClientId }, setAuth0ConnectionDetails] =
    React.useState<{
      auth0Connection: Auth0Connection;
      auth0ClientId: string;
    }>(() => getInitialAuth0Details(location.pathname));

  React.useEffect(() => {
    if (accessToken && accessToken !== previousAccessToken) {
      const connectionDetails = getAuth0ConnectionFromToken(accessToken);
      if (connectionDetails) {
        setAuth0ConnectionDetails(connectionDetails);
      }
    }

    const refreshToken = userLoggedIn();

    if (!refreshToken && !accessToken) {
      setAuth0ConnectionDetails(getAuth0ConnectionFromPath(location.pathname));
    }
  }, [accessToken, previousAccessToken, location.pathname]);

  if (!auth0ClientId || window.location.protocol !== 'https:') {
    return (
      <ApolloLinksWrapper
        connection={auth0Connection}
        store={store}
        client={client}
      >
        {children}
      </ApolloLinksWrapper>
    );
  }

  const redirectUrl = getRedirectUrl(auth0Connection);

  return (
    <Auth0TenantContext.Provider
      value={{
        auth0ClientId,
        auth0Domain: AUTH0_DOMAIN,
      }}
    >
      <Auth0Provider
        key={auth0ClientId}
        domain={AUTH0_DOMAIN}
        clientId={auth0ClientId}
        authorizationParams={{
          redirect_uri: `${window.location.origin}${redirectUrl}`,
          custom_claims_uri: window.location.origin,
          audience: AUTH0_API_AUDIENCE,
          scope: 'openid profile email read:current_user',
        }}
        onRedirectCallback={appState => {
          history.push(appState?.redirectUri ?? window.location.pathname);
        }}
        cacheLocation="localstorage"
        useRefreshTokens
        useRefreshTokensFallback
      >
        <ApolloLinksWrapper
          connection={auth0Connection}
          store={store}
          client={client}
        >
          {children}
        </ApolloLinksWrapper>
      </Auth0Provider>
    </Auth0TenantContext.Provider>
  );
};
