// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga, Channel } from 'redux-saga';
import { buffers, eventChannel } from 'redux-saga';
import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import type { UserTypingPayload } from '@numbox/react';

import { userTyping } from '@numbox/react';
import type { ApiConversation } from '@numbox/services';
import type { InitPubnubAction } from '~/actions/pubnub';
import type {
  PubNubStateChangeEvent,
  PubNubConversationTimestamp,
} from '../constants';
import { PUBNUB_ACTION_TYPES } from '../constants';

import { getUserId } from '../modules/auth';

// Number of messages the event channel can hold before it resizes
const INITIAL_MESSAGE_BUFFER_SIZE = 25;

export function initializeChannel(client: PubNub) {
  return eventChannel(emit => {
    // The first argument to an event channel is a subscribe function that takes a single
    // parameter 'emit' that is used to place messages on the channel.
    const eventListener = {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'm' implicitly has an 'any' type.
      message: m => emit(m),
    };

    client.addListener(eventListener);

    // When `close` is called on an eventChannel, the function returned from the
    // subscriber function is invoked to terminate the subscription. In this case, if our
    // channel is ever closed, we want to clean up our existing event listener so
    // we stop listening for incomingmessages.
    //
    // See https://redux-saga.js.org/docs/advanced/Channels.html for additional detail
    const unsubscribeFromChannel = () => client.removeListener(eventListener);
    return unsubscribeFromChannel;
  }, buffers.expanding(INITIAL_MESSAGE_BUFFER_SIZE));
}

export function subscribeToPresence(client: PubNub) {
  return eventChannel(emit => {
    const eventListener = {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
      presence: p => emit(p),
    };

    client.addListener(eventListener);
    const unsubscribeFromChannel = () => client.removeListener(eventListener);

    return unsubscribeFromChannel;
  }, buffers.expanding(INITIAL_MESSAGE_BUFFER_SIZE));
}

export function* handleIncomingPresenceEvent(
  message: PubNubStateChangeEvent<UserTypingPayload>,
): Generator<any, void, void> {
  if (message.action !== PUBNUB_ACTION_TYPES.STATE_CHANGE) {
    return;
  }
  const { user, conversationId, isTyping } = message.state;
  const activeUserId = yield select(getUserId);
  // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
  if (user.id === activeUserId) {
    return;
  }
  yield put(userTyping(user, conversationId, isTyping));
}

export function* handlePresenceEvents(
  action: InitPubnubAction,
): Generator<any, void, void> {
  const client = action.payload.pubnubClient;
  // @ts-expect-error ts-migrate(2314) FIXME: Generic type 'Channel<T>' requires 1 type argument... Remove this comment to see the full error message
  const channel: Channel | null | undefined = yield call(
    subscribeToPresence,
    client,
  );
  if (!channel) {
    return;
  }
  while (true) {
    try {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'void' is not assignable to type 'PubNubState... Remove this comment to see the full error message
      const presenceMessage:
        | PubNubStateChangeEvent<UserTypingPayload>
        | null
        | undefined = yield take(channel);

      if (presenceMessage) {
        yield call(handleIncomingPresenceEvent, presenceMessage);
      }
    } catch (error) {
      yield call(console.log, 'Error while receiving a pubnub message', error);
    }
  }
}

export const pubnubConversationAssigned = (conversation: ApiConversation) => ({
  type: 'PUBNUB_CONVERSATION_ASSIGNED',
  payload: {
    conversation,
  },
});

export const pubnubConversationArchived = (conversation: ApiConversation) => ({
  type: 'PUBNUB_CONVERSATION_ARCHIVED',
  payload: {
    conversation,
  },
});

export const pubnubConversationRestored = (conversation: ApiConversation) => ({
  type: 'PUBNUB_CONVERSATION_RESTORED',
  payload: {
    conversation,
  },
});

export const pubnubConversationReceived = (conversation: ApiConversation) => ({
  type: 'PUBNUB_CONVERSATION_RECEIVED',
  payload: {
    conversation,
  },
});

export const pubnubConversationDeleted = (id: string) => ({
  type: 'PUBNUB_CONVERSATION_DELETED',
  payload: {
    id,
  },
});

export const pubnubConversationRecovered = (id: string) => ({
  type: 'PUBNUB_CONVERSATION_RECOVERED',
  payload: {
    id,
  },
});

export const pubnubConversationsUpdated = (
  inboxId: string,
  conversations: Array<PubNubConversationTimestamp>,
  areArchived: boolean,
) => ({
  type: 'PUBNUB_CONVERSATIONS_UPDATED',
  payload: {
    inboxId,
    conversations,
    areArchived,
  },
});

export function* watchPubnubInitialization(): Saga<any> {
  yield takeEvery('INIT_PUBNUB', handlePresenceEvents);
}
