import { gql } from '@apollo/client';

export const GET_USER_PHONES = gql`
  query getUserPhones($userId: String!) {
    userPhones(userId: $userId) {
      id
      mdn
      type
    }
  }
`;
