import { gql } from '@apollo/client';

export const SET_FORTELLIS_LOOKUP_ID = gql`
  mutation SetFortellisLookupId($input: SetFortellisLookupIdInput) {
    setFortellisLookupId(input: $input) {
      place {
        id
        name
        fortellisLookupId
      }
    }
  }
`;
