import { gql } from '@apollo/client';

export const DISABLE_FORTELLIS_SUBSCRIPTION = gql`
  mutation DisableFortellisSubscription(
    $input: DisableFortellisSubscriptionInput!
  ) {
    disableFortellisSubscription(input: $input) {
      fortellisSubscription {
        id
        name
        status
      }
    }
  }
`;
