import { Components, Theme } from '@mui/material';

export const MuiRating: Components<Theme>['MuiRating'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.secondary.main,
      gap: theme.spacing(0.5),
    }),
    iconEmpty: ({ theme }) => ({
      color: theme.palette.secondary.main,
    }),
  },
};
