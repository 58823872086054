import { call, race, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { userTyping, DISCARD_USER, STOPPED_TYPING } from './module';

export const PUBNUB_ACTION_TYPES = {
  STATE_CHANGE: 'state-change',
};
export const getOnOtherUserTypingHandler =
  (
    // eslint-disable-next-line no-unused-vars
    dispatch: (action: any) => void,
    userId: string,
  ) =>
  (message: any) => {
    if (message.action !== PUBNUB_ACTION_TYPES.STATE_CHANGE) {
      return;
    }

    const { user, conversationId, isTyping } = message.state;

    if (userId && user.id === userId) {
      return;
    }

    dispatch(userTyping(user, conversationId, isTyping));
  };

// eslint-disable-next-line @typescript-eslint/no-empty-function
const dropCallbackResponse = () => {};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'selectPubnubPayload' implicitly has an ... Remove this comment to see the full error message
export const getOnTyping = (selectPubnubPayload, channel) =>
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'isTyping' implicitly has an 'any' type.
  function* onTyping(isTyping, action) {
    const { conversationId, discardedUser } = action.payload;

    if (!conversationId) {
      return;
    }

    let retryCount = 0;
    const MAX_RETRIES = 3;

    /*
      There's a chance that we try to set a typing indicator
      before our initial pubnub registration has completed and flushed to redux.
      If that's the case, we retry and wait until we get a valid client from redux.
    */
    while (retryCount < MAX_RETRIES) {
      const { activeUserId, pubnubClient, user } = yield call(
        selectPubnubPayload,
      );

      if (!pubnubClient) {
        return;
      }

      const pubnubStatePayload = {
        state: {
          user:
            action.type === DISCARD_USER
              ? { ...discardedUser, teams: [] }
              : { ...user, teams: [] },
          conversationId,
          isTyping,
          timestamp: new Date().getTime(),
        },
        uuid: activeUserId,
        channels: [channel],
      };

      try {
        // See for API
        // eslint-disable-next-line
        // https://www.pubnub.com/docs/web-javascript/presence#setting_custom_presence_state_set_state
        if (pubnubClient) {
          yield call(
            pubnubClient.setState,
            pubnubStatePayload,
            dropCallbackResponse,
          );
          return;
        }
      } catch (exception) {
        // eslint-disable-next-line
        yield call(console.warn, exception);
      }

      const DELAY_IN_MS = 500;
      // PubNub client has not yet initialized; wait and try again.
      const { stoppedTyping } = yield race({
        delayed: delay(DELAY_IN_MS),
        stoppedTyping: take(STOPPED_TYPING),
      });

      if (
        stoppedTyping &&
        stoppedTyping.payload.conversationId === conversationId
      ) {
        // The user has stopped typing already in this conversation, no reason to continue.
        return;
      }

      retryCount += 1;
    }
  };
