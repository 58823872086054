import { gql } from '@apollo/client';

import { OutboundCallCustomizationFragment } from '../../fragments/gql/outboundCallCustomization.gql';

export const GET_OUTBOUND_CONFERENCE_CALL_CUSTOMIZATIONS = gql`
  query getOutboundCallCustomizations($userId: String!) {
    outboundCallCustomizations(userId: $userId) {
      ...OutboundCallCustomizationFields
    }
  }
  ${OutboundCallCustomizationFragment}
`;
