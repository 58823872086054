import { combineReducers } from 'redux';
import type { Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { TypingReducer } from '@numbox/react';
import type { WebReducerState } from './types';

import AdminAuthReducer from './adminAuth';
import AuthReducer from './auth';
import {
  AccountReducer,
  ChannelReducer,
  FacebookIntegrationReducer,
  PlaceReducer,
} from './numboxClient';

import GreetingReducer from '../modules/greetings';
import { SIGN_OUT_USER_SUCCESS } from '../modules/auth';
import UserReducer from '../modules/users';
import Auth0Reducer from './auth0';
import DemoReducer from './demo';
import LaunchDarklyReducer from './launchdarkly';
import PubNubReducer from './pubnub';
import FacebookLoginReducer from './facebookLogin';
import ViewReducer from './view';
import NotificationReducer from '../modules/notifications';
import TranslationReducer from '../modules/translation';
import SpeechReducer from '../modules/speech';
import ActivationReducer from '../modules/activation';
import PhoneLineReducer from '../modules/phonelines';
import PhonelineProvisionReducer from '../modules/phonelineProvision';
import AnswersReducer from '../modules/answers';
import SubscriptionReducer from '../modules/subscription';
import IntercomReducer from '../modules/intercom';
import AdminReducer from '../modules/admin';
import WidgetReducer from '../modules/widget';
import { ModalReducer } from '../modules/modal';
import { InboxReducer } from '../modules/inbox';
import ToastReducer from './toast';

export const history = createBrowserHistory();

const appReducers = combineReducers({
  accounts: AccountReducer,
  activation: ActivationReducer,
  admin: AdminReducer,
  answers: AnswersReducer,
  adminAuth: AdminAuthReducer,
  auth: AuthReducer,
  auth0: Auth0Reducer,
  channels: ChannelReducer,
  demo: DemoReducer,
  facebookIntegration: FacebookIntegrationReducer,
  facebookLogin: FacebookLoginReducer,
  featureFlags: LaunchDarklyReducer,
  greetings: GreetingReducer,
  inbox: InboxReducer.InboxReducer,
  intercom: IntercomReducer,
  modal: ModalReducer,
  notifications: NotificationReducer,
  phonelineProvision: PhonelineProvisionReducer,
  phonelines: PhoneLineReducer,
  pubnub: PubNubReducer,
  router: connectRouter(history),
  speech: SpeechReducer,
  subscriptions: SubscriptionReducer,
  places: PlaceReducer,
  toastNotifications: ToastReducer,
  translation: TranslationReducer,
  typing: TypingReducer,
  users: UserReducer,
  views: ViewReducer,
  widget: WidgetReducer,
});

const CLEAR_REDUX_ACTIONS = [
  SIGN_OUT_USER_SUCCESS,
  'AUTH_USER.FAILURE',
  'REFRESH_TOKEN.FAILURE',
  'UPDATE_PASSWORD.SUCCESS',
  'VERIFY_USER.SUCCESS',
  'SIGNUP.SUCCESS',
  'AUTH_USER.SUCCESS',
  'STORE_AUTH0_TOKEN',
];

const rootReducer: Reducer = (state: WebReducerState, action: any) => {
  /**
   * Special case: admin tool account creation
   * In order to avoid an auth exception on post-creation
   * account details page redirect due to null reassignment
   * of the redux access tokens we want to preserve the state.
   * This will allow for the account to be created and the user immediately
   * be re-directed to the newly created accounts details page without
   * any auth errors when issuing the gql query to fetch the account info.
   */
  if (
    action.type === 'SIGNUP.SUCCESS' &&
    action.meta &&
    action.meta.fromAdminTool
  ) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'WebReducerState' is not assignab... Remove this comment to see the full error message
    return appReducers(state, action);
  }

  if (CLEAR_REDUX_ACTIONS.includes(action.type)) {
    return {
      ...appReducers(undefined, action),
      auth0: state.auth0,
      router: state.router,
      modal: state.modal,
      toastNotifications: state.toastNotifications,
    };
  }
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'WebReducerState' is not assignab... Remove this comment to see the full error message
  return appReducers(state, action);
};

export default rootReducer;
