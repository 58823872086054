import { ApolloProvider } from '@apollo/client';
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';

import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';

import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { ConnectedRouter, routerMiddleware } from 'connected-react-router';

import { StyledEngineProvider } from '@mui/material/styles';

import * as Sentry from '@sentry/browser';

import { GraphQLClient } from '@numbox/apollo';

import { CoreThemeProvider } from '@library/themes/core';
import rootSaga from './sagas';
import { isUnsupportedBrowser } from './util/browser';

import reducers, { history } from './reducers';

import './index.css';
import locales from './locales';

import App from './components/App';
import { Auth0Wrapper } from './components/Auth0Wrapper';
import ScrollToTop from './components/ScrollToTop';
import { BrowserUnsupportedBanner } from './components/main/AppShell/Banners/BrowserUnsupportedBanner';
import { KeyboardListener } from './components/shared/KeyboardListener/KeyboardListener';
import { ApolloClientProvider } from './providers/ApolloClientProvider';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (p: any) => any;
  }
}

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const reduxRouter = routerMiddleware(history);

const sagaMiddleware = createSagaMiddleware();
const enhancer = composeEnhancers(
  applyMiddleware(reduxRouter, reduxThunk, sagaMiddleware),
);

const store = createStore(reducers, enhancer);

sagaMiddleware.run(rootSaga);

addLocaleData([...en, ...es]);

const userLocale = navigator.language.split('-')[0];
// const userLocale = 'en';  // uncomment to force the app into a language

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'locale' implicitly has an 'any' type.
const getLanguage = locale => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (locales[locale]) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return locales[locale];
  }
  return locales.en;
};

if (import.meta.env.PROD && !isUnsupportedBrowser) {
  Sentry.init({
    dsn: 'https://e7ad17ee7ce34d27aacc0472509bdfc7@o93523.ingest.sentry.io/210691',
    release: import.meta.env.VITE_CIRCLE_SHA1,
  });
}

const AppContainer = ({ children }: { children: React.ReactNode }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {isUnsupportedBrowser && <BrowserUnsupportedBanner />}
    <div
      style={{
        position: 'relative',
        flexGrow: 1,
      }}
    >
      {children}
    </div>
  </div>
);
const domNode = document.getElementById('root');
const root = createRoot(domNode as Element);
root.render(
  <Provider store={store}>
    {/* @ts-expect-error children prop not completed */}
    <IntlProvider
      locale={userLocale}
      defaultFormats={getLanguage(userLocale).formats}
      messages={getLanguage(userLocale).messages}
    >
      <ApolloProvider client={GraphQLClient}>
        {/* @ts-expect-error ts-migrate(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message */}
        <ApolloClientProvider>
          <KeyboardListener>
            {/* @ts-expect-error children prop not completed */}
            <ConnectedRouter history={history}>
              <Auth0Wrapper client={GraphQLClient} store={store}>
                <ScrollToTop>
                  <StyledEngineProvider injectFirst>
                    <CoreThemeProvider>
                      <AppContainer>
                        <App />
                      </AppContainer>
                    </CoreThemeProvider>
                  </StyledEngineProvider>
                </ScrollToTop>
              </Auth0Wrapper>
            </ConnectedRouter>
          </KeyboardListener>
        </ApolloClientProvider>
      </ApolloProvider>
    </IntlProvider>
  </Provider>,
);
