import { gql } from '@apollo/client';

export const GET_FORTELLIS_SUBSCRIPTIONS = gql`
  query fortellisSubscriptions($accountId: String!) {
    fortellisSubscriptions(queryFilter: { accountId: { equal: $accountId } }) {
      edges {
        node {
          id
          subscriptionId
          name
          status
        }
      }
    }
  }
`;
