import { gql } from '@apollo/client';

export const CREATE_USER_PHONE = gql`
  mutation createUserPhone($input: CreateUserPhoneInput!) {
    createUserPhone(input: $input) {
      userPhone {
        id
        mdn
        type
      }
    }
  }
`;
